import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/ubuntu/flomesh.io-main/src/layouts/Fixed/DocMdx.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "core"
    }}>{`Core`}</h2>
    <h3 {...{
      "id": "pipelines"
    }}>{`Pipelines`}</h3>
    <ul>
      <li parentName="ul">{`New pipeline type for reading from files (or stdin)`}</li>
      <li parentName="ul">{`Added onStart()/onEnd() pipeline lifecycle callbacks`}</li>
      <li parentName="ul">{`Support idle timeout for inbound and outbound connections`}</li>
      <li parentName="ul">{`Transparent mode port listening`}</li>
      <li parentName="ul">{`Socket reading performance optimization`}</li>
    </ul>
    <h3 {...{
      "id": "metrics"
    }}>{`Metrics`}</h3>
    <ul>
      <li parentName="ul">{`Collect metrics on a repo node from all workers connected to it`}</li>
      <li parentName="ul">{`Record metric history on a repo node for up to 5 minutes for charts showing on the Admin UI`}</li>
      <li parentName="ul">{`Expose metrics from a repo node to Prometheus with gzip compression`}</li>
      <li parentName="ul">{`Added new metrics for inbound/outbound data and connection times`}</li>
    </ul>
    <h3 {...{
      "id": "logging"
    }}>{`Logging`}</h3>
    <ul>
      <li parentName="ul">{`Send real-time log streams from workers to the repo`}</li>
    </ul>
    <h3 {...{
      "id": "codebases"
    }}>{`Codebases`}</h3>
    <ul>
      <li parentName="ul">{`Send codebase update notifications to workers via WebSocket to reload script with no delay`}</li>
      <li parentName="ul">{`Support deletion of a codebase`}</li>
    </ul>
    <h2 {...{
      "id": "pipyjs"
    }}>{`PipyJS`}</h2>
    <ul>
      <li parentName="ul">{`New nesting syntax for in-place anonymous sub-pipelines`}</li>
      <li parentName="ul">{`Added pipy.solve() to evaluate script from external files`}</li>
      <li parentName="ul">{`Added -e option for evaluating PipyJS script from the command line`}</li>
      <li parentName="ul">{`Support function argument default values`}</li>
      <li parentName="ul">{`String size upper limit raised to 256MB`}</li>
      <li parentName="ul">{`Performance optimization of object literal construction`}</li>
    </ul>
    <h2 {...{
      "id": "protocols"
    }}>{`Protocols`}</h2>
    <h3 {...{
      "id": "http2--grpc"
    }}>{`HTTP/2 & gRPC`}</h3>
    <ul>
      <li parentName="ul">{`Support full HTTP/2 specification except for server push`}</li>
      <li parentName="ul">{`Extended demuxHTTP()/muxHTTP() to support HTTP/2 protocol negotiation`}</li>
    </ul>
    <h3 {...{
      "id": "http1x"
    }}>{`HTTP/1.x`}</h3>
    <ul>
      <li parentName="ul">{`Support HTTP message trailers`}</li>
      <li parentName="ul">{`Proper handling of responses with status code 100`}</li>
      <li parentName="ul">{`Detect plain HTTP/1 traffic by detectProtocol() filter`}</li>
      <li parentName="ul">{`HTTP/1.x decoder performance optimization`}</li>
    </ul>
    <h3 {...{
      "id": "mqtt"
    }}>{`MQTT`}</h3>
    <ul>
      <li parentName="ul">{`Added a new pipeline communication model for MQTT-like protocols using input()/output() filters`}</li>
      <li parentName="ul">{`Standard-conformance bug fixes`}</li>
    </ul>
    <h3 {...{
      "id": "websocket"
    }}>{`WebSocket`}</h3>
    <ul>
      <li parentName="ul">{`Added WebSocket codecs`}</li>
      <li parentName="ul">{`Added a communication channel between the worker and the repo via WebSocket`}</li>
    </ul>
    <h3 {...{
      "id": "udp"
    }}>{`UDP`}</h3>
    <ul>
      <li parentName="ul">{`Added UDP listeners`}</li>
    </ul>
    <h3 {...{
      "id": "tls"
    }}>{`TLS`}</h3>
    <ul>
      <li parentName="ul">{`Support ALPN in acceptTLS() and connectTLS() filters`}</li>
      <li parentName="ul">{`OpenSSL version bumped to 1.1.1o`}</li>
    </ul>
    <h2 {...{
      "id": "api"
    }}>{`API`}</h2>
    <ul>
      <li parentName="ul">{`New API for general purpose logging`}</li>
      <li parentName="ul">{`Added algo.Quota as a replacement of the old "accounts" used by throttling filters`}</li>
      <li parentName="ul">{`Added Data.shiftWhile() and shiftTo() for simple data format parsing in PipyJS`}</li>
      <li parentName="ul">{`Added LoadBalancer.next() with builtin connection pools`}</li>
      <li parentName="ul">{`Added algo.uuid() for UUIDv4 generation`}</li>
      <li parentName="ul">{`JSON.encode() performance optimization`}</li>
    </ul>
    <h2 {...{
      "id": "filters"
    }}>{`Filters`}</h2>
    <ul>
      <li parentName="ul">{`New filter branch() that supports conditional embedded anonymous sub-pipelines`}</li>
      <li parentName="ul">{`New filter depositMessage() for buffering large messages in a temp file`}</li>
      <li parentName="ul">{`New filter deframe() for general purpose protocol deframing using PipyJS`}</li>
      <li parentName="ul">{`New filter compressMessage() for deflate/gzip compression`}</li>
      <li parentName="ul">{`New filter decodeMultipart() for decoding a MIME multipart message into multiple messages`}</li>
      <li parentName="ul">{`Support caps on the maximum queue size per session for all muxer filters`}</li>
      <li parentName="ul">{`Support Brotli algorithm in decompressMessage() filter`}</li>
      <li parentName="ul">{`Support writing to stdout using tee()`}</li>
      <li parentName="ul">{`Support timeout in filter wait()`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      